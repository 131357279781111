$mainFont: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif';

$font-size-xxl: 48px;
$font-size-xl: 32px;
$font-size-l: 24px;
$font-size-m: 18px;
$font-size-s: 16px;

$line-height-xxl: 1.08;
$line-height-xl: 1.12;
$line-height-l: 1.17;
$line-height-m: 1.33;
$line-height-s: 1.25;


