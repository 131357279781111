.form {
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 20px
}

.password-field {
 margin-bottom: 30px;
}
