@import '/src/styles/variables.scss';
@import "color-variables";

* {
  box-sizing: border-box;
}

html, body, #root  {
  font-family: $mainFont;
  margin: 0;
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
}

h1, h2, h3, h4, p {
  margin: 0;
}

ul {
  padding: 0;
}

// Не отображать страшный серый фон в input в Chrome
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

// Не отображать страшную ссылку с token и local-time в swagger
span.url {
  display: none;
}

//Симпатичный скроллбар
::-webkit-scrollbar {
  height: 12px;
  width: 12px;
  background: rgb(255, 255, 255);
}

::-webkit-scrollbar-thumb {
  background: $main-blue;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar-corner {
  background: $main-blue;
}