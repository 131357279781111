@import '/src/styles/mixins.scss';

.container {
  @include content-restrictor;
  h2 {
    margin: 20px 0 40px 20px;
  }
}

.sort-buttons{ 
  align-self: start;
  display: flex;
  gap: 20px;
  margin: 30px 0;
}

.icon {
  padding-left: 10px;
}

.services{
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin-bottom: 30px;
}

.adder{
  padding-bottom: 30px;
}

.add-button {
  align-self: flex-end;
}
