.container {
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap:10px;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  @media (max-width: 900px)  {
    h3 {
      margin-bottom: 10px;
    }
    flex-direction: column;
    align-items: stretch;
  }
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-container > a:first-child {
  margin-right: 10px;
  @media (max-width: 600px)  {
    margin-bottom: 10px;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
  }
}

.input{
  flex-grow: 1;
}