@import '/src/styles/mixins.scss';

.container {
  @include content-restrictor;
  h2 {
    margin: 20px 0 40px 20px;
  }
  padding-bottom: 40px;
}

.columns {
  display: flex;
  gap: 20px;
  height: calc(100vh - 100px);
}

.column {
  flex-grow: 1;
  flex-basis: 0;
  height: 100%;
  overflow-y: scroll;
}

.imputs {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 40px;
}

.input{
  display: block;
  width: 100%;
}