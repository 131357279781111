@import '/src/styles/variables.scss';

.wrapper {
  z-index: 5;
  position: fixed;
  background-color: $light-gray;
  opacity: 0.5;
  top: 100px;
  left: 20px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  border: 2px solid black;
  cursor: pointer;
}

.wrapper:hover {
  color: $main-blue;
  border-color: $main-blue;
}

