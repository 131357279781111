.container {
  height: 100%;
}

.editor {
  height: 100%;
}

.button {
  display: block;
  margin: 20px 20px 20px auto;
}