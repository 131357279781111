.container {
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  @media (max-width: 900px)  {
    h3 {
      margin-bottom: 10px;
    }
    flex-direction: column;
  }
}

.button-container {
  display: flex;
  @media (max-width: 600px)  {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.button-container > a:not(last-child) {
  margin-right: 10px;
  @media (max-width: 600px)  {
    margin-bottom: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.alert-content {
  margin: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.buttons {
  display: flex;
  justify-content: space-around;
}

.button {
  background-color: #FB3A2F!important;
}
.button:hover {
  background-color: #a1251e!important;
}
