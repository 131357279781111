@import '/src/styles/variables.scss';

.container {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid $main-blue;
  h1 {
    margin-left: 20px;
  }
}
.link {
  color: #000;
}
.icon {
  padding-left: 10px;
}
.button {
  background-color: #ffffff !important;
  color: black;
}
.button:hover {
  background-color: #ffffff !important;
  color: $main-blue;
}
